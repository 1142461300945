import { RewardsExtension } from '/~/core/extension'
import { useKyc } from '/~/extensions/user-kyc/composables/use-kyc'
import { useVerificationRoutes } from '/~/composables/access/use-verification-routes'

class UserKycExtension extends RewardsExtension {
  async setEwalletRoutes() {
    const { isKycEnabled } = useKyc()

    if (!isKycEnabled.value) {
      return []
    }

    const { setEwalletVerificationRoutes } = useVerificationRoutes()

    return setEwalletVerificationRoutes()
  }
}

export default UserKycExtension
